import React from "react"
import homeIcon from "../images/sidebar-images/home_icon.svg"
import Layout from "../components/layout"
import { Container, Row } from 'react-bootstrap/'
import SEO from "../components/seo"
import { Link } from "gatsby"
import TopHeader from "../components/top-header"

const Demo = () => (
  <Layout>
    <TopHeader />
    <SEO
    description='noindex'
    title='Retmes' />
    <Container style={{ textAlign: '-webkit-center', marginTop: '10%' }}>
      <h2 style={{ fontWeight: '100' }}>Aradığınız sayfayı bulamadık.</h2>
      <div style={{ marginTop: '3rem', marginLeft: '0' }} className='retmes-detail-button'>
        <Link to='/' style={{ color: 'white' }}>
          <img src={homeIcon} style={{ width: '1.7rem' }} alt="retmes home icon" />

        </Link>
      </div>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>

  </Layout>
)

export default Demo
